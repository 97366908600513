import { FC, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { Box, Stack, Typography, MenuItem, BoxProps, Alert } from "@mui/material";
import axios from "axios";
import { Auth } from "@aws-amplify/auth";
import LoadingButton from "~/components/LoadingButton";
import FormField from "~/components/FormField";
import { withPrettyLabels } from "~/utils/validation";

type CreateUserValues = {
  email: string;
  displayName: string;
  role: string;
};

const roleOptions: Record<string, string> = {
  peerworker: "Peer worker",
  seniorpeerworker: "Senior peer worker",
  admin: "Admin",
};

const createUserSchema = withPrettyLabels(
  yup.object({
    email: yup.string().email().required(),
    displayName: yup.string().required(),
    role: yup.string().required().oneOf(["peerworker", "seniorpeerworker", "admin"]),
  })
);

type Props = {
  sx?: BoxProps["sx"];
};
const CreateUserForm: FC<Props> = ({ sx }) => {
  const methods = useForm<CreateUserValues>({
    resolver: yupResolver(createUserSchema),
    defaultValues: { role: "peerworker" },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;

  const [result, setResult] = useState({ success: false, message: "" });

  const role = watch("role");

  const onSubmit = async (data: CreateUserValues) => {
    try {
      setResult({ success: false, message: "" });

      const res = await axios.post(`${process.env.REACT_APP_API_BASE}/users`, data, {
        headers: {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });

      if (res) {
        setResult({
          success: true,
          message: `User created with id:\n${res.data.id}`,
        });
      }
    } catch (ex) {
      console.error(ex);
      setResult({
        success: false,
        message:
          axios.isAxiosError(ex) && ex.response?.data?.errorMessage
            ? ex.response?.data?.errorMessage
            : (ex as Error).message,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={sx}>
        <Stack
          spacing={2}
          sx={{
            p: 2,
            borderRadius: 2,
            border: theme => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="h2">Create a new user</Typography>
          <FormField name="email" label="Email address" required />
          <FormField
            name="displayName"
            label="Display name"
            required
            helperText="Allow letters, numbers, hyphens or underscores only"
          />
          <FormField name="role" label="Role" defaultValue="peerworker" required select>
            {Object.entries(roleOptions).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormField>

          {result.message && (
            <Alert severity={result.success ? "success" : "error"} sx={{ my: 2 }}>
              <Typography>{result.message}</Typography>
            </Alert>
          )}

          <LoadingButton color="primary" type="submit" loading={isSubmitting}>
            Create {roleOptions[role]}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default CreateUserForm;

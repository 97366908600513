import { Box, Stack, Typography } from "@mui/material";

import aboriginalFlag from "~/assets/flag-aboriginal.svg";
import ausFlag from "~/assets/flag-australia.svg";
import prideFlag from "~/assets/flag-pride.svg";
import torresStraitFlag from "~/assets/flag-torres-strait.svg";

const flags = [
  { src: aboriginalFlag, label: "Aboriginal flag" },
  { src: torresStraitFlag, label: "Torres Strait islander flag" },
  { src: prideFlag, label: "Rainbow flag" },
  { src: ausFlag, label: "Australian flag" },
];

const Footer = () => {
  return (
    <Box
      className="page-footer"
      sx={{
        "& > div > img": {
          border: "1px solid #ffffff",
        },
        mt: "auto",
        mb: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack direction="row" spacing={0.5} sx={{ mb: 1, justifyContent: "center" }}>
        <Typography variant="caption">
          PeerChat by{" "}
          <a target="_blank" href="https://au.reachout.com" rel="noreferrer">
            ReachOut.com
          </a>
        </Typography>
        <Typography variant="caption">
          |{" "}
          <a target="_blank" href="https://au.reachout.com/faqs" rel="noreferrer">
            FAQs
          </a>
        </Typography>
        <Typography variant="caption">
          |{" "}
          <a target="_blank" href="https://au.reachout.com/our-policies" rel="noreferrer">
            Our policies
          </a>
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ height: 32 }}>
        {flags.map(({ src, label }) => (
          <img key={src} src={src} alt={label} height="100%" width="auto" />
        ))}
      </Stack>
    </Box>
  );
};

export default Footer;

import type { EveryMessage } from "@sendbird/uikit-react/types/types";
import { GroupChannelUserMessage } from "../../utils/chat";
import HiddenMessage from "./HiddenMessage";
import Message from "./Message";

type CustomMessageProps = {
  message: EveryMessage;
  hideMessagesBefore?: number;
  visibleOverrides: number[];
  user: User | null;
  addVisibleMessage: (messageId: number) => void;
  sessionJoinedDate: string;
};

export const CustomMessage = ({
  message,
  hideMessagesBefore,
  visibleOverrides,
  user,
  addVisibleMessage,
  sessionJoinedDate,
}: CustomMessageProps) => {
  const customMessage = message as GroupChannelUserMessage; // Cast to extended type as `_sender` property is actually `sender` to get the userId. Sendbird types are not consistent with what is being returned in the app

  if (hideMessagesBefore && customMessage.customType) return <></>;

  if (
    hideMessagesBefore &&
    customMessage.createdAt < hideMessagesBefore &&
    !visibleOverrides.includes(customMessage.messageId)
  ) {
    return (
      <HiddenMessage message={customMessage} user={user!} addVisibleMessage={addVisibleMessage} />
    );
  }

  return <Message message={customMessage} sessionJoinedDate={sessionJoinedDate} />;
};

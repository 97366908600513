import { FC } from "react";

import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Checkbox,
  BoxProps,
  CheckboxProps,
} from "@mui/material";
import { get } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

type Props = {
  name: string;
  label: FormControlLabelProps["label"];
  id?: string;
  boxSx?: BoxProps["sx"];
} & CheckboxProps;

const FormCheckbox: FC<Props> = ({ name, label, boxSx, id: idProp, ...props }) => {
  const { control } = useFormContext();

  // default field id to name value. id is used to link a11y labels
  const id = idProp || name;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors, isSubmitted, touchedFields } }) => {
        const errorMessage = get(errors, `${name}.message`, get(errors, name));

        const hasError = Boolean((isSubmitted || get(touchedFields, name)) && errorMessage);
        return (
          <Box display="flex" flexDirection="column" sx={boxSx}>
            <FormControlLabel
              control={<Checkbox {...field} {...props} id={id} />}
              label={label}
              sx={{ m: 0 }}
            />
            {hasError && <ErrorMessage message={errorMessage?.toString()} sx={{ pt: 0, mt: -1 }} />}
          </Box>
        );
      }}
    />
  );
};

export default FormCheckbox;

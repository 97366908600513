import { FC } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, Portal, SxProps } from "@mui/material";

type Props = {
  isShowing: boolean;
  onClick: () => void;
  sx?: SxProps;
};

const HideMessageButton: FC<Props> = ({ isShowing, onClick, sx = {} }) => {
  const Icon = isShowing ? VisibilityOff : Visibility;
  const message = isShowing ? "Hide all messages so far" : "Show hidden messages";
  return (
    <Portal
      container={() => document.querySelector(".sendbird-message-input-wrapper__message-input")}
    >
      <IconButton
        id="hide-messages"
        title={message}
        onClick={onClick}
        sx={{ order: -1, alignSelf: "center", mr: 1, ...sx }}
        size="large"
      >
        <Icon sx={{ width: 24, height: 24, color: "white" }} />
      </IconButton>
    </Portal>
  );
};

export default HideMessageButton;

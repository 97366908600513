import { FC } from "react";
import { Box, Typography } from "@mui/material";
import ErrorMessage from "./ErrorMessage";

type Props = {
  currentValue: number;
  maxValue: number;
  showErrorMessage?: boolean;
  countDirectionUp?: boolean;
};

const CountLimitIndicator: FC<Props> = ({
  currentValue,
  maxValue,
  showErrorMessage = true,
  countDirectionUp = true,
}) => {
  const max = currentValue === maxValue;

  return (
    <>
      {
        <Box
          sx={{
            display: "flex",
            alignSelf: "flex-end",
          }}
        >
          <Typography align="right" variant="caption" mr={1} color={max ? "error" : "inherit"}>
            {countDirectionUp
              ? `${currentValue}/${maxValue}`
              : `${maxValue - currentValue} characters`}
          </Typography>
        </Box>
      }
      {max && showErrorMessage && (
        <ErrorMessage
          className="error"
          message={`You've reached the character limit (${currentValue}/${maxValue}).`}
          sx={{ pt: 0 }}
        />
      )}
    </>
  );
};
export default CountLimitIndicator;

import { FC, useState, useContext, useEffect, useMemo } from "react";

import { Button, Container, Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "~/components/providers/AuthProvider";
import { formatBookingDate } from "~/utils/date";
import { getMyBookings } from "~/services/booking";

import NavButton from "~/components/NavButton";
import NavLink from "~/components/NavLink";
import LoadingIndicator from "~/components/LoadingIndicator";

import DefaultLayout from "./layouts/DefaultLayout";
import LoginScreen from "./LoginScreen";
import StatusMessage from "~/components/StatusMessage";
import BrandedBox from "~/components/containers/BrandedBox";

type Props = {
  notFound?: boolean;
};

const DashboardScreen: FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useContext(AuthContext);
  const [sessionId, setSessionId] = useState("");
  const [bookingId, setBookingId] = useState("");

  const [sessionTime, setSessionTime] = useState<[Dayjs, Dayjs]>();
  const [loading, setLoading] = useState(true);

  const getNextSession = async () => {
    const myBookings = await getMyBookings();
    if (myBookings.length) {
      setSessionTime([dayjs(myBookings[0].start), dayjs(myBookings[0].end)]);
      setSessionId(myBookings[0].sessionId);
      setBookingId(myBookings[0].id);
    }
    setLoading(false);
  };
  const now = dayjs();

  useEffect(() => {
    getNextSession();
  }, []);

  const intro = useMemo(() => {
    if (sessionTime && !now.isSame(sessionTime[0], "day")) {
      return `You have a chat booked soon, you'll receive a reminder on the day and you'll be able to join it from here as well.
      If you need to cancel any time let us know by managing your booking below.`;
    } else if (sessionTime && now.isBefore(sessionTime[0])) {
      return `You have a chat today! You can join below and your peer worker will let you know when they're ready.
      If you need to cancel any time let us know by managing your booking below.`;
    } else if (sessionTime && now.isAfter(sessionTime[1])) {
      return `Your chat ended earlier but you can still jump back in to see your messages.
      Since the chat is finished you won't be able to write new messages though.`;
    }

    return loading
      ? undefined
      : "You don't have any upcoming chats right now. You can book one below, or update your account details.";
  }, [sessionTime, loading, now]);

  if (!isLoggedIn) return <LoginScreen />;

  return (
    <DefaultLayout
      title="Your account"
      noHeaderColour
      intro={intro}
      middleSection={
        <BrandedBox
          themeType="navy"
          sx={{
            mt: -2,
            mb: 3,
            py: 4,
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              px: [5, 0],
              display: "flex",
              flexDirection: "column",
              alignItems: ["stretch", "flex-start"],
            }}
          >
            {loading && <LoadingIndicator sx={{ alignSelf: "center" }} />}
            {sessionTime && (
              <>
                <Typography fontWeight={700}>
                  Your chat session:
                  <br />
                  {sessionTime && formatBookingDate(sessionTime[0])}
                </Typography>
                {now.isSame(sessionTime[0], "day") && (
                  <NavButton to={`/session/${sessionId}`} sx={{ mt: 2, mb: 2 }}>
                    Start your chat session
                  </NavButton>
                )}
                <NavLink to={`/booking/${bookingId}`} color="inherit">
                  Manage your booking
                </NavLink>
              </>
            )}
            {!loading && !sessionTime && (
              <NavButton
                sx={{ mt: 2, mb: 2 }}
                to={isLoggedIn ? "/booking/availability" : "/signup"}
                type="submit"
              >
                Book a chat
              </NavButton>
            )}
          </Container>
        </BrandedBox>
      }
    >
      <Stack spacing={2} alignItems={["stretch", "flex-start"]}>
        <NavButton variant="outlined" to="/my-details" color="primary">
          Your details
        </NavButton>
        <Button
          color="urgent"
          component="a"
          href="https://au.reachout.com/urgent-help"
          target="_blank"
        >
          Find urgent help
        </Button>
      </Stack>
      {location.state?.successMessage && (
        <StatusMessage
          severity="success"
          message={location.state?.successMessage}
          // clear on close so you don't get confusing repeat messages
          onClose={() =>
            navigate(window.location.pathname, {
              replace: true,
              state: { ...location.state, successMessage: "" },
            })
          }
        />
      )}
    </DefaultLayout>
  );
};

export default DashboardScreen;

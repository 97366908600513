import { string as str, object, type ObjectSchema, type TestFunction } from "yup";
import { capitalize, lowerCase } from "lodash";

// recursively update schema with Sentence case field name as label
export const withPrettyLabels = <T extends Record<string, any>>(
  schema: ObjectSchema<T>
): ObjectSchema<T> => {
  const fields = schema.fields as Record<string, any>;

  const newFields = Object.entries(fields).reduce(
    (agg, [k, v]) => {
      const newField = v.fields ? withPrettyLabels(v) : v.label(capitalize(lowerCase(k)));
      return {
        ...agg,
        [k]: newField,
      };
    },
    {} as Record<string, any>
  );

  return object(newFields) as ObjectSchema<T>;
};

export const isValidPhone = (value?: string): boolean =>
  Boolean(
    value?.match(/^(\+|\d)[\d\s]+$/) && value.replace(/[^\d+]/g, "").match(/^(\d{10}|\+\d{11})$/)
  );

const invalidMobileMessage = "Mobile number must be in the correct format e.g. 0491570156";
const invalidEmailMessage = "Email address must be in the correct format e.g. email@example.com";

export const validateEmailOrPhone: TestFunction<string | undefined> = (value, ctx) => {
  if (!(isValidPhone(value) || (value?.includes("@") && str().email().isValidSync(value)))) {
    if (value?.match(/^(\+|\d)[\d\s]+$/)) {
      return ctx.createError({
        message: invalidMobileMessage,
      });
    } else if (value?.includes("@") && ctx.path !== "mobileNumber") {
      return ctx.createError({
        message: invalidEmailMessage,
      });
    }

    let customMessage;
    if (ctx.path === "mobileNumber") {
      customMessage = "A valid mobile number is required";
    } else {
      customMessage = "An email address or mobile number is required";
    }

    return ctx.createError({
      message: customMessage,
    });
  }

  return true;
};

// avoid XSS and HTML injection by restricting allowed symbols.
// avoid causing issues for PWs by limiting length
export const displayNameSchema = str()
  .transform(val => val.trim())
  .required("Display name is required, and can't be only spaces")
  .matches(/^[a-z0-9!._\-' ]+$/i, {
    message: "Display name can only contain letters, numbers, spaces and these symbols: '!-._",
  })
  .max(30);

// during signup allow either email or phone to be supplied, but check the
// format is valid after detecting something email-like or phone-like
export const emailOrPhoneSchema = str()
  .required("An email address or mobile number is required")
  .test("emailAddressOrMobileNumber", validateEmailOrPhone);

export const phoneSchema = str()
  .required("A valid mobile number is required")
  .test("mobileNumber", validateEmailOrPhone);

// when updating the email/phone on the My Details screen you can't
// switch from email to phone (or vice versa) - so just validate
// against the existing account type
export const emailOrPhoneSameTypeOnlySchema = str()
  .required()
  .when("$accountTypeEmail", {
    is: true,
    then: schema => schema.email(invalidEmailMessage).label("Email address"),
    otherwise: schema =>
      schema
        .test({
          test: isValidPhone,
          message: invalidMobileMessage,
        })
        .label("Mobile number"),
  });

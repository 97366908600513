import { FC, useMemo } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { PostCheckInNames, postCheckInValues, postSliderValues } from "~/content/checkInData";
import CheckInSliderIteration from "../chat/CheckInSliderIteration";

type Props = {
  checkIn: {
    numberValue: number | undefined;
    textValue: PostCheckInNames;
    touched: boolean;
  };
  handleSliderChange: (event: Event, newValue: number | number[]) => void;
};

const SliderStep: FC<Props> = ({ checkIn, handleSliderChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const weatherImgUrl = useMemo(() => {
    if (checkIn.textValue === "Skipped check-in")
      return isMobile
        ? postCheckInValues["Skipped check-in"].mobileIconSrc
        : postCheckInValues["Skipped check-in"].iconSrc;

    return isMobile
      ? postCheckInValues[checkIn.textValue].mobileIconSrc
      : postCheckInValues[checkIn.textValue].iconSrc;
  }, [checkIn.textValue, isMobile]);

  return (
    <Stack
      spacing={{ xs: "unset", md: 5 }}
      direction={{ xs: "row", md: "column" }}
      justifyContent={{ xs: "space-evenly", md: "column" }}
      alignItems="center"
      width="100%"
    >
      <Box
        width={{ xs: "110px", md: "unset" }}
        maxWidth={{ xs: "unset", md: "450px" }}
        component="img"
        height={{ xs: "unset", md: "200px" }}
        src={weatherImgUrl}
        alt={""}
      />
      <Box width={{ xs: "160px", md: "100%" }}>
        <CheckInSliderIteration
          sliderValues={postSliderValues}
          vertical={isMobile}
          checkInValue={checkIn.numberValue}
          touched={checkIn.touched}
          checkinStringValue={Object.keys(postCheckInValues) as PostCheckInNames[]}
          handleSliderChange={handleSliderChange}
        />
      </Box>
    </Stack>
  );
};

export default SliderStep;

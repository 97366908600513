import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";

type Props = {
  onClose: () => void;
};
const PeerWorkInfo: FC<Props> = ({ onClose }) => {
  return (
    <Stack px={2} direction="column" justifyContent="space-between">
      <Typography textAlign="center">
        This chat will be with a peer worker. Our peer workers have experience with mental health
        challenges and tough times. They’ve been on their own journey to recovery and have been
        trained to support others. Peer workers are here to listen in a non-judgmental, private and
        confidential space.
      </Typography>
      <Button aria-label="Next" color="primary" onClick={onClose} sx={{ mt: 3 }} fullWidth>
        Next
      </Button>
    </Stack>
  );
};

export default PeerWorkInfo;

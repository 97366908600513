import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SignupForm from "~/components/auth/SignupForm";
import DefaultLayout from "./layouts/DefaultLayout";

import gaCreate from "~/utils/gaCreate";

const SignupScreen: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  function onSignup() {
    window.dataLayer?.push({
      event: "peerchat_signup",
      send_to: process.env.REACT_APP_GA_ID as string,
    } as { event: string; send_to: string });
    gaCreate();

    window.ga?.("send", "event", "PeerChat", "Click", "Step 2 - Create my account");
  }

  const onLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/login", { state: location.state });
  };

  return (
    <DefaultLayout title="Your details" noHeaderColour sx={{ pt: 2 }}>
      <SignupForm onSignup={onSignup} onLoginClick={onLoginClick} />
    </DefaultLayout>
  );
};

export default SignupScreen;

import { type FC, useContext, useState } from "react";
import { Box, Button } from "@mui/material";

import { ReactComponent as BreathingBackground } from "~/assets/breathingExercise.svg";
import Pulser from "./Pulser";
import { svgAnimation } from "./svgAnimation";
import type { WaitingStage } from "./WaitingRoomHelpSeeker";
import { PeerWorkerContext } from "../providers/PeerWorkerProfileProvider";

import PopUp from "./PopUp";
import HeaderButton from "../chat/HeaderButton";

type Props = {
  hasStarted: boolean;
  onJoin: () => void;
  setWaitingStage: (stage: WaitingStage) => void;
};

const BreathingExercise: FC<Props> = ({ hasStarted, onJoin, setWaitingStage }) => {
  const [infoMessage, setInfoMessage] = useState(true);
  const [joinMessage, setJoinMessage] = useState(true);
  const peerWorkerProfile = useContext(PeerWorkerContext);

  return (
    <Box
      sx={{
        mx: -5,
        position: "relative",

        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: ["column", "row"],
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          "& > svg": {
            width: "100%",
            height: "100%",
            ...svgAnimation,
          },
        }}
      >
        <BreathingBackground />

        <Button
          id="back-to-waiting"
          onClick={() => setWaitingStage("waiting")}
          color="navy"
          sx={{
            position: "absolute",
            top: "75%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 100,
            px: 18,
          }}
        >
          Close
        </Button>

        {!joinMessage && (
          <HeaderButton
            id="join-chat-breathing"
            className="join-chat"
            buttonLabel="Join"
            onClick={onJoin}
          />
        )}

        <Pulser
          sx={{
            position: "absolute",
            top: theme => theme.spacing(4),
            left: "50%",
            transform: "translateX(-50%)",
          }}
          inDuration={4}
          pauseDuration={2}
          outDuration={5}
          inMessage="Breathe in"
          pauseMessage="Hold"
          outMessage="Breathe out"
          stoppedMessage="Breathe in"
          showStopButton={false}
          start={!infoMessage}
        />
      </Box>

      <PopUp
        open={infoMessage}
        heading={`We'll let you know as soon as ${peerWorkerProfile.displayName} has sent you a message.`}
        bodyText="There’s no rush – take your time with the exercise and join the
                chat whenever you’re ready."
        buttonText="Got it!"
        onExit={() => setInfoMessage(false)}
        onButtonClick={() => setInfoMessage(false)}
      />

      {hasStarted && (
        <PopUp
          open={joinMessage}
          heading="When you’re ready, join the chat!"
          bodyText="Your peer worker is ready and has sent you a hello message."
          buttonText="Join the chat"
          onExit={() => setJoinMessage(false)}
          onButtonClick={onJoin}
        />
      )}
    </Box>
  );
};

export default BreathingExercise;

import { FC } from "react";
import { VisibilityOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { colours } from "~/theme";
import { GroupChannelUserMessage } from "../../utils/chat";

type Props = {
  message: GroupChannelUserMessage;
  user: User;
  addVisibleMessage: (messageId: number) => void;
};

const HiddenMessage: FC<Props> = ({ message, user, addVisibleMessage }) => {
  return (
    <Box
      sx={{
        opacity: 0.8,
        display: "flex",
        justifyContent: message?.sender?.userId === user?.userId ? "flex-end" : "flex-start",
      }}
    >
      <IconButton onClick={() => addVisibleMessage(message.messageId)} className="show-message">
        <VisibilityOff
          sx={{
            color: theme => (theme.palette.mode === "dark" ? colours.secondary.main : "grey.700"),
          }}
        />
      </IconButton>
    </Box>
  );
};

export default HiddenMessage;

import { type FC, useEffect, useState, createContext } from "react";
import { getProfile } from "~/services/users";

const emptyProfile = {
  displayName: "",
  avatarUrl: "",
  profile: "",
};
export const PeerWorkerContext = createContext<UserProfile>(emptyProfile);

type PeerWorkerProfileProviderProps = {
  userId?: string;
  children: React.ReactNode;
};
const PeerWorkerProfileProvider: FC<PeerWorkerProfileProviderProps> = ({ children, userId }) => {
  const [value, setValue] = useState(emptyProfile);
  useEffect(() => {
    if (userId) {
      getProfile(userId).then(setValue);
    }
  }, [userId]);

  return <PeerWorkerContext.Provider value={value}>{children}</PeerWorkerContext.Provider>;
};

export default PeerWorkerProfileProvider;

import { FC } from "react";
import { Link, LinkProps as MuiLinkProps } from "@mui/material";
import { Link as RouterLink, LinkProps } from "react-router-dom";

const NavLink: FC<Omit<MuiLinkProps, "ref"> & LinkProps> = ({ children, ...props }) => {
  return (
    <Link component={RouterLink} {...props}>
      {children}
    </Link>
  );
};

export default NavLink;

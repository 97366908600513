import { FC } from "react";
import HeaderAction from "../HeaderAction";
import { Button, Stack } from "@mui/material";
import LockChannelButton from "./LockChannelButton";
import { SessionState } from "~/utils/chat";

type Props = {
  sessionState: SessionState;
  onSurveyClick: React.MouseEventHandler;
  onLockClick: () => void;
  isLocked: boolean;
};

const PeerWorkerHeaderButtons: FC<Props> = ({
  sessionState,
  onSurveyClick,
  onLockClick,
  isLocked,
}) => {
  return (
    <HeaderAction>
      <Stack
        spacing={1}
        direction="row"
        sx={{
          ".MuiButton-root": {
            fontSize: { xs: "10px", sm: "unset" },
            py: 0,
          },
        }}
      >
        <Button disabled={sessionState === SessionState.Ended} onClick={onSurveyClick} color="peri">
          Start survey
        </Button>
        <LockChannelButton isLocked={isLocked} onClick={onLockClick} />
      </Stack>
    </HeaderAction>
  );
};

export default PeerWorkerHeaderButtons;

import { Box, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { pxToRem } from "~/theme";
import Profile from "../Profile";
import { PeerWorkerContext } from "../providers/PeerWorkerProfileProvider";

const StartMessage: FC = () => {
  const peerWorker = useContext(PeerWorkerContext);
  return (
    <Box
      sx={{
        py: 2,
        px: [2, 3],

        "& .MuiTypography-body1": {
          fontSize: pxToRem(14),
        },
      }}
    >
      <Profile
        {...peerWorker}
        nameColour={theme => (theme.palette.mode === "dark" ? "text.primary" : "navy.main")}
        roleName="Peer worker"
      />
      <Typography mt={2}>
        This is a private, confidential and non-judgemental space. You can view your chat transcript
        for up to 24 hours after this session.
      </Typography>
    </Box>
  );
};

export default StartMessage;

import { FC, useMemo } from "react";
import {
  createFilterOptions,
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from "@mui/material";
import FormField, { FormFieldProps } from "../FormField";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";

type Props = FormFieldProps & {
  users: ContactableUser[];
};
const UserAutocomplete: FC<Props> = ({ users, name, helperText, label, ...props }) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: unknown) => {
      const val = option as ContactableUser;
      return `${val.id} ${val.displayName}`;
    },
    ignoreCase: true,
  });
  const {
    setValue,
    watch,
    formState: { errors, touchedFields, isSubmitted },
  } = useFormContext();
  const idValue = watch(name);
  const value = useMemo(() => users?.find(u => u.id === idValue) || null, [idValue, users]);
  const errorMessage = get(errors, `${name}.message`, get(errors, name));
  const hasError = Boolean((isSubmitted || get(touchedFields, name)) && errorMessage);

  return (
    <FormField
      name={name}
      component={Autocomplete}
      filterOptions={filterOptions}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} helperText={helperText} label={label} error={hasError} />
      )}
      options={users}
      getOptionLabel={(option: unknown) =>
        `${(option as any).displayName || "<no display name>"} [${(option as any).id}]`
      }
      autoHighlight
      onChange={(e: any, value: unknown) => {
        setValue(name, (value as ContactableUser)?.id || "", {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
      }}
      value={value}
      setErrorProp={false}
      {...props}
    />
  );
};

export default UserAutocomplete;

import { Box, Button, Stack, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import PopUp from "./PopUp";
import { WaitingStage } from "./WaitingRoomHelpSeeker";
import { PeerWorkerContext } from "../providers/PeerWorkerProfileProvider";
import HeaderButton from "../chat/HeaderButton";

type Props = {
  hasStarted: boolean;
  onJoin: () => void;
  setWaitingStage: (stage: WaitingStage) => void;
};

const NatureSounds: FC<Props> = ({ hasStarted, onJoin, setWaitingStage }) => {
  const [infoMessage, setInfoMessage] = useState(true);
  const [joinMessage, setJoinMessage] = useState(true);
  const peerWorkerProfile = useContext(PeerWorkerContext);

  return (
    <>
      <PopUp
        open={infoMessage}
        heading={`We'll let you know as soon as ${peerWorkerProfile.displayName} has sent you a message.`}
        bodyText="There’s no rush – take your time with the exercise and join the

        chat whenever you’re ready."
        buttonText="Got it!"
        onExit={() => setInfoMessage(false)}
        onButtonClick={() => setInfoMessage(false)}
      />

      {hasStarted && (
        <PopUp
          open={joinMessage}
          heading="When you’re ready, join the chat!"
          bodyText="Your peer worker is ready and has sent you a hello message."
          buttonText="Join the chat"
          onExit={() => setJoinMessage(false)}
          onButtonClick={onJoin}
        />
      )}

      {!joinMessage && (
        <HeaderButton
          id="join-chat-breathing"
          className="join-chat"
          buttonLabel="Join"
          onClick={onJoin}
        />
      )}
      <Stack spacing={2} mt={2}>
        <Typography variant="h2">Choose your nature sound</Typography>

        <Typography>
          Some people find the sounds of nature to be relaxing. Feel free to use this playlist and
          find a track you like.
        </Typography>
      </Stack>
      <Box>
        <iframe
          src="https://open.spotify.com/embed/playlist/2NYSGDvF41uRybGjSimxA9?utm_source=generator&theme=0"
          width="100%"
          height="380"
          frameBorder="0"
          title="spotifyFrame"
          style={{ borderRadius: "12px" }}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </Box>
      <Box pb={2}>
        <Button
          id="back-to-waiting"
          onClick={() => setWaitingStage("waiting")}
          sx={{ width: "100%" }}
        >
          Close
        </Button>
      </Box>
    </>
  );
};

export default NatureSounds;

import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as BrainIcon } from "~/assets/brainIcon.svg";
import { colours } from "~/theme";
import ActionMessage from "./ActionMessage";
import { GroupChannelUserMessage } from "../../utils/chat";

type Props = {
  message: GroupChannelUserMessage;
  user: User;
};

const ThinkingTimeMessage: FC<Props> = ({ message, user }) => {
  return (
    <ActionMessage>
      <Box
        component={BrainIcon}
        sx={{
          position: "absolute",
          left: 8,
          top: "50%",
          transform: "translateY(-50%)",
          color: theme => (theme.palette.mode === "dark" ? colours.secondary.main : "text.primary"),
        }}
      />
      {message?.sender?.userId === user?.userId ? (
        <>
          I'm thinking... <span aria-hidden="true">🤔</span>
        </>
      ) : (
        <>
          <Typography component="span" fontWeight="bold">
            {message?.sender?.nickname}
          </Typography>{" "}
          needs a moment to think
        </>
      )}
    </ActionMessage>
  );
};

export default ThinkingTimeMessage;

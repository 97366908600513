import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ReactComponent as BrainIcon } from "~/assets/brainIcon.svg";
import { ReactComponent as HeartIcon } from "~/assets/hero-icon-heart.svg";
import { VisibilityOff } from "@mui/icons-material";
import { colours } from "~/theme";

export type InfoContent = "buttonInfo" | "feedback";

type Props = {
  infoContent: InfoContent;
};

const InfoMessage: FC<Props> = ({ infoContent }) => {
  switch (infoContent) {
    case "buttonInfo":
      return (
        <Grid container spacing={2}>
          <Grid item xs={2} alignSelf="center">
            <Box
              sx={{
                color: theme => (theme.palette.mode === "dark" ? colours.white.main : "navy.main"),
              }}
            >
              <BrainIcon width="25" height="25" />
            </Box>
          </Grid>
          <Grid item xs={10}>
            Let your peer worker know you need some thinking time.
          </Grid>
          <Grid item xs={2}>
            <VisibilityOff
              fontSize="medium"
              sx={{
                color: theme => (theme.palette.mode === "dark" ? colours.white.main : "navy.main"),
              }}
            />
          </Grid>
          <Grid item xs={10}>
            Hide your previous messages
          </Grid>
        </Grid>
      );
    case "feedback":
      return (
        <Stack
          alignItems="center"
          justifyContent={{ xs: "center", md: "flex-start" }}
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          px={{ md: 3 }}
        >
          <HeartIcon width="44" height="44" />
          <Typography textAlign="center">
            At the end of the chat, you'll have the opportunity to give some feedback and help
            improve PeerChat for other young people.
          </Typography>
        </Stack>
      );
    default:
      return null;
  }
};

export default InfoMessage;

import { FC, useCallback, useContext } from "react";
import { IconButton, Portal } from "@mui/material";
import { useSendbirdStateContext, sendBirdSelectors } from "@sendbird/uikit-react";
import { AuthContext } from "~/components/providers/AuthProvider";
import { SxProps } from "@mui/material/styles";
import { ReactComponent as BrainIcon } from "~/assets/brainIcon.svg";
import type { GroupChannel as GroupChannelType } from "@sendbird/chat/groupChannel";

// TODO: custom material input and emoji/gif selector goes here

type Props = {
  isLocked: boolean;
  channel: GroupChannelType;
  sx?: SxProps;
};

const ThinkingButton: FC<Props> = ({ isLocked, channel, sx = {} }) => {
  const { isStaffMember } = useContext(AuthContext);
  const context = useSendbirdStateContext();

  const handleThinkingTime = useCallback(async () => {
    const sendMessage = sendBirdSelectors.getSendUserMessage(context);

    sendMessage(channel, {
      message: "I need some time to think.",
      customType: "thinking_time",
    }).onFailed(error => {
      console.error("Failed to start session!", error);
    });
  }, [channel, context]);

  if (isStaffMember) return null;

  return (
    <Portal
      container={() => document.querySelector(".sendbird-message-input-wrapper__message-input")}
    >
      <IconButton
        id="thinking-time-button"
        aria-label="Start thinking time"
        sx={{
          order: -2,
          alignSelf: "center",
          ...sx,
        }}
        onClick={handleThinkingTime}
        disabled={isLocked}
        size="large"
      >
        <BrainIcon width="24" height="24" color="white" />
      </IconButton>
    </Portal>
  );
};

export default ThinkingButton;

import { FC } from "react";
import { Box } from "@mui/material";
import { ThemeBoxProps } from "./containers";
import BrandedBox from "./containers/BrandedBox";

type Props = {
  imageSource?: string;
  imageAlt?: string;
} & ThemeBoxProps;

const RoundedContainer: FC<Props> = ({ imageSource, imageAlt, children, ...boxProps }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
      <Box
        component="img"
        sx={{
          width: 100,
          height: 100,
          borderRadius: "50%",
          backgroundColor: "background.default",
          border: theme => `10px solid ${theme.palette.background.default}`,
          transform: "translateY(50px)",
          mt: "-60px",
        }}
        src={imageSource}
        alt={imageAlt || ""}
      />
      <BrandedBox
        themeType="navyGrey"
        sx={{
          borderRadius: "30px",
          px: { xs: 2, sm: 10 },
          pb: { xs: 2, sm: 5 },
          pt: "60px",
        }}
        {...boxProps}
      >
        {children}
      </BrandedBox>
    </Box>
  );
};

export default RoundedContainer;

import { FC, useContext } from "react";
import { Button, Stack, Typography, Divider } from "@mui/material";

import { PeerWorkerContext } from "../providers/PeerWorkerProfileProvider";

import RoundedContainer from "../RoundedContainer";
import HeaderButton from "../chat/HeaderButton";
import { greyTheme } from "../containers/BrandedBox";

type Props = {
  onJoin: () => void;
};

const Ready: FC<Props> = ({ onJoin }) => {
  const { displayName, avatarUrl, pronouns, profile } = useContext(PeerWorkerContext);

  return (
    <RoundedContainer imageSource={avatarUrl} imageAlt="" themes={[greyTheme]}>
      <Stack spacing={2} sx={{ textAlign: "center" }}>
        <Typography variant="h2" component="p">
          {displayName}
        </Typography>
        <Typography fontWeight="bold">{[pronouns, profile].filter(Boolean).join(" ● ")}</Typography>

        <Divider sx={{ "&&": { mt: 4, mb: 2 } }} />

        <Typography variant="h4" component="h3">
          When you’re ready, join the chat!
        </Typography>
        <Typography>Your peer worker is ready and has sent you a hello message.</Typography>

        <Button color="primary" fullWidth sx={{ "&&": { mt: 5 } }} onClick={onJoin}>
          Join the chat
        </Button>
        <HeaderButton buttonLabel="Join" onClick={onJoin} />
      </Stack>
    </RoundedContainer>
  );
};

export default Ready;

import { FC, useState } from "react";

import { Auth } from "@aws-amplify/auth";
import { Alert, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormField from "../FormField";
import { useLocation } from "react-router-dom";
import LoadingButton from "../LoadingButton";
import { normaliseUsername } from "./utils";
import { withPrettyLabels, emailOrPhoneSchema } from "~/utils/validation";
import ForgotPasswordNewForm from "./ForgotPasswordNewForm";

interface ForgotPasswordValues {
  emailAddressOrMobileNumber: string;
}

const requestSchema = withPrettyLabels(
  yup.object({
    emailAddressOrMobileNumber: emailOrPhoneSchema,
  })
);

const ForgotPasswordForm: FC = () => {
  const location = useLocation();
  const [serverError, setServerError] = useState("");

  const [, resetFromUrl] = location.search.match(/reset=([^&]+)/i) || [];

  const [resetRequested, setResetRequested] = useState(!!resetFromUrl);
  const requestMethods = useForm<ForgotPasswordValues>({
    resolver: yupResolver(requestSchema),
  });
  const username = requestMethods.watch("emailAddressOrMobileNumber");

  const onRequest = async (data: ForgotPasswordValues) => {
    try {
      setServerError("");
      await Auth.forgotPassword(normaliseUsername(data.emailAddressOrMobileNumber));
      setResetRequested(true);
    } catch (ex) {
      console.error(ex);
      // ignore enumeration error response - still visible in console / network traffic though
      if ((ex as Error).message?.includes("not found")) {
        setResetRequested(true);
      } else {
        setServerError((ex as Error).message || "Failed to request password reset");
      }
    }
  };

  if (resetRequested) {
    return <ForgotPasswordNewForm username={username || decodeURIComponent(resetFromUrl)} />;
  }

  return (
    <FormProvider {...requestMethods}>
      <form onSubmit={requestMethods.handleSubmit(onRequest)} noValidate>
        <Stack spacing={2}>
          <Typography>
            We&apos;ll send you a code which you can use to set a new password.
          </Typography>
          <FormField name="emailAddressOrMobileNumber" label="Email or Phone number" required />

          {serverError && <Alert severity="error">{serverError}</Alert>}

          <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
            <LoadingButton
              color="primary"
              type="submit"
              loading={requestMethods.formState.isSubmitting}
            >
              Reset password
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordForm;

import { Box, Slider } from "@mui/material";
import { FC } from "react";
import { colours } from "~/theme";

type Props = {
  sliderValues: { value: number; label?: string }[];
  vertical?: boolean;
  checkinStringValue: string[];
  checkInValue: number | undefined;
  touched: boolean;
  handleSliderChange: (event: Event, newValue: number | number[]) => void;
};

const horizontalSx = {
  height: 10,
  bottom: "-40px",
  "& .MuiSlider-markLabel": {
    top: "-60px",
  },
  "& .MuiSlider-rail": {
    height: 10,
    width: "101%", // if width is 100% it doesnt fill the bar so need extra 1% to fill
  },
};

const verticalSx = {
  width: 10,
  right: "-110px",
  "& .MuiSlider-markLabel": {
    left: "-110px",
  },
  "& .MuiSlider-rail": {
    height: "102%", // if height is 100% it doesnt fill the bar so need extra 2% to fill
    top: "-10px",
  },
};

const CheckInSlider: FC<Props> = ({
  sliderValues,
  checkinStringValue,
  vertical = false,
  checkInValue,
  touched,
  handleSliderChange,
}) => {
  const getCheckinStringValue = (index: number, value: number): string => checkinStringValue[index];

  // suggested from mui to avoid left and right arrows being used on vertical slider
  const preventHorizontalKeyboardNavigation = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  };

  return (
    <Box height={vertical ? "340px" : "unset"}>
      <Box height={vertical ? "40%" : "unset"} position={vertical ? "fixed" : "unset"}>
        <Slider
          aria-label="checkin-slider"
          // these require a function passed in with a particular signature, though "value"
          // is not necessary
          getAriaValueText={getCheckinStringValue}
          valueLabelFormat={getCheckinStringValue}
          orientation={vertical ? "vertical" : "horizontal"}
          track={false}
          min={1}
          max={5}
          defaultValue={3}
          marks={sliderValues}
          sx={[
            {
              color: colours.white.main,
              "& .MuiSlider-markLabel": {
                fontSize: 14,
                whiteSpace: "normal",
                width: vertical ? "100px" : "120px",
                textAlign: "center",
                color: colours.white.main,
              },
              "& .MuiSlider-track": {
                border: "none",
              },
              "& .MuiSlider-thumb": {
                backgroundColor: touched ? colours.plum.main : colours.white.main,
                width: "50px",
                height: "50px",
                "&:hover, &.Mui-focusVisible, &.Mui-active": {
                  boxShadow: "none",
                },
              },
              "& .MuiSlider-rail": {
                opacity: "50%",
                color: colours.white.main,
                borderRadius: "10px",
              },
              "& .MuiSlider-mark, &.MuiSlider-markActive": {
                backgroundColor: colours.white.main,
                height: 10,
                width: 10,
                opacity: 1,
                borderRadius: "100%",
              },
            },
            vertical ? verticalSx : horizontalSx,
          ]}
          value={checkInValue ?? 3} // mui throws an error for undefined value even though need this to use the default value of 3
          onChange={handleSliderChange}
          onKeyDown={vertical ? preventHorizontalKeyboardNavigation : () => {}}
        />
      </Box>
    </Box>
  );
};

export default CheckInSlider;

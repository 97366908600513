import { Box, Divider } from "@mui/material";
import { colours } from "~/theme";

export const DateSeparator = ({ date }: { date: string }) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      position={"relative"}
      marginTop={2}
      paddingBottom={3}
    >
      <Divider color={colours.primary.main} />
      <Box
        position={"absolute"}
        bottom={"50%"}
        width="100%"
        height="100%"
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          paddingX={2}
          bgcolor={theme =>
            theme.palette.mode === "dark" ? colours.background.dark : colours.buff.main
          }
          fontWeight={"bold"}
          fontSize={"12px"}
        >
          {date}
        </Box>
      </Box>
    </Box>
  );
};

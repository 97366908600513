import { FC } from "react";
import { Button, Typography } from "@mui/material";
import HeaderAction from "../HeaderAction";
import BrandedBox from "../containers/BrandedBox";

type Props = {
  disableButton?: boolean;
  onClick?: () => void;
  buttonLabel: string;
  id?: string;
  className?: string;
};

const HeaderButton: FC<Props> = ({
  disableButton = false,
  onClick,
  buttonLabel,
  id,
  className,
}) => {
  return (
    <HeaderAction>
      <BrandedBox themeType="navy">
        <Button
          id={id}
          className={className}
          disabled={disableButton}
          variant="contained"
          onClick={onClick}
          color="primary"
          sx={{
            p: 0,
          }}
        >
          <Typography fontWeight="700">{buttonLabel}</Typography>
        </Button>
      </BrandedBox>
    </HeaderAction>
  );
};

export default HeaderButton;

import ThemeBox from "~/components/containers/ThemeBox";
import { lightTheme } from "~/theme";
import { Box, Link, Typography } from "@mui/material";

const displayStartDate = new Date(2023, 11, 18);
const displayEndDate = new Date(2024, 0, 9);

const showBreakMessage = (startDate: Date, endDate: Date) => {
  const today = new Date(Date.now());
  return today >= startDate && today < endDate;
};

export const BreakMessage = () => {
  return showBreakMessage(displayStartDate, displayEndDate) ? (
    <ThemeBox themes={[lightTheme]}>
      <Box
        sx={{
          bgcolor: "background.default",
          borderRadius: "6px",
          p: 2,
        }}
      >
        <Typography color="primary" fontWeight={700}>
          ReachOut is taking a break from 22 December 2023. We’ll be back on 9 January 2024.
        </Typography>{" "}
        If you are experiencing negative thoughts or feelings, or need to speak to someone
        immediately, check out{" "}
        <Link href="https://au.reachout.com/urgent-help" target="_blank" id="urgent-help-link">
          urgent help
        </Link>{" "}
        to find a 24/7 service that can help.
      </Box>
    </ThemeBox>
  ) : null;
};

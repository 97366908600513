import { FC } from "react";
import { BoxProps, createTheme } from "@mui/material";
import { merge } from "lodash-es";
import ThemeBox, { ThemeLike } from "./ThemeBox";
import { colours } from "~/theme";
import { Theme } from "@mui/material/styles";

const makeTheme = (backgroundDefault: string, textColour?: string, primary?: Object) => {
  return (parent: Theme) =>
    createTheme(
      merge({}, parent, {
        palette: {
          background: {
            default: backgroundDefault,
          },
          text: {
            primary: textColour,
          },
          primary: primary,
        },
      })
    );
};

export const navyTheme: ThemeLike = parent =>
  makeTheme(parent.palette.navy.main, colours.text.light, colours.ice)(parent);

export const navyGreyTheme: ThemeLike = parent =>
  makeTheme(
    parent.palette.mode === "dark" ? colours.grey.main : parent.palette.navy.main,
    colours.text.light,
    colours.ice
  )(parent);

export const transparentTheme: ThemeLike = parent =>
  makeTheme("transparent", colours.text.light, colours.ice)(parent);

export const mallardTheme: ThemeLike = parent =>
  makeTheme(colours.mallard.light, colours.text.dark)(parent);

export const whiteTheme: ThemeLike = parent =>
  makeTheme(parent.palette.mode === "dark" ? colours.grey.main : colours.white.main)(parent);

export const greyTheme: ThemeLike = parent =>
  makeTheme(
    parent.palette.mode === "dark" ? colours.grey.main : parent.palette.headingBg.main,
    parent.palette.mode === "dark" ? colours.text.light : colours.text.dark
  )(parent);

const brandType: { [key: string]: ThemeLike } = {
  grey: greyTheme,
  navy: navyTheme,
  mallard: mallardTheme,
  white: whiteTheme,
  navyGrey: navyGreyTheme,
  transparent: transparentTheme,
};

type BackgroundType = "grey" | "navy" | "mallard" | "white" | "navyGrey" | "transparent";

type BrandedBoxProps = {
  themeType: BackgroundType;
};

const BrandedBox: FC<BoxProps & BrandedBoxProps> = ({ themeType, children, ...props }) => (
  <ThemeBox themes={[brandType[themeType]]} sx={{ bgcolor: "background.default", ...props.sx }}>
    {children}
  </ThemeBox>
);

export default BrandedBox;

import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { Email, Message } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Interweave } from "interweave";

import { getSecureAxiosClient } from "~/utils/auth";
import { getUser } from "~/services/users";
import DefaultLayout from "./layouts/DefaultLayout";
import { AuthContext } from "~/components/providers/AuthProvider";
import ContactForm from "~/components/admin/ContactForm";
import LoadingIndicator from "~/components/LoadingIndicator";

const client = getSecureAxiosClient(process.env.REACT_APP_API_BASE!);

type MessageProps = {
  message?: string;
  data?: {
    subject?: string;
    template?: string;
    attributes?: Record<string, string>;
  };
};
const MessageBody: FC<MessageProps> = ({ message, data: { subject, attributes } = {} }) => (
  <Box my={1}>
    {subject && (
      <Typography variant="h6" component="p">
        {subject}
      </Typography>
    )}
    {/* For emails, use rendered HTML so we see it how the user intended */}
    {message && subject && <Interweave content={message} />}
    {/* For text, can just render it as text */}
    {message && !subject && <Typography>{message}</Typography>}
    {attributes &&
      Object.entries(attributes).map(([k, v]) => (
        <Box display="flex" alignItems="center">
          <Typography fontWeight="bold">{k}</Typography>
          <Typography>{v}</Typography>
        </Box>
      ))}
  </Box>
);

const todayDate = dayjs().format("DD/MM/YYYY");

const InboxScreen: FC = () => {
  const { user } = useContext(AuthContext);
  const currentUserId = user!.userId;
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState<ContactableUser>();
  const [messages, setMessages] = useState<any[]>([]);
  const [shouldUpdate, setShouldUpdate] = useState(0);

  useEffect(() => {
    if (!userId) return;

    setLoading(true);
    Promise.all([
      getUser(userId).then(res => setSelectedUser(res)),
      client
        .get(`/inbox/${userId}`)
        .then(res => setMessages(res.data.sort((a: any, b: any) => b.timestamp - a.timestamp))),
    ]).finally(() => setLoading(false));
  }, [userId, shouldUpdate]);

  //  triggers the useEffect to fetch latest messages
  const handleNewMessage = () => setShouldUpdate(Date.now());

  return (
    <DefaultLayout containerWidth="lg" sx={{ py: [2, 5] }}>
      {selectedUser && <Typography variant="h1">Inbox for {selectedUser.displayName}</Typography>}
      {loading && !messages?.length ? (
        <LoadingIndicator />
      ) : (
        <Grid container spacing={2} sx={{ "&&": { mx: -2 } }}>
          <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
            {loading && (
              <LoadingIndicator
                size="2em"
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 1,
                }}
              />
            )}
            {!!messages.length && (
              <Stack spacing={1} sx={{ maxHeight: "60vh", overflowY: "auto" }}>
                {messages.map((m, i) => {
                  const Icon = m.type === "email" ? Email : Message;
                  const sender = m.data?.sender || "";
                  const myMessage = sender === currentUserId;
                  const userMessage = sender === userId;
                  return (
                    <Box
                      key={`${i}-${m.timestamp}`}
                      sx={{
                        alignSelf: myMessage ? "flex-end" : "flex-start",
                        minWidth: 300,
                        pt: 1,
                        px: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: myMessage ? "flex-end" : "flex-start",
                      }}
                      data-heap-redact-text="true"
                    >
                      <Typography variant="caption">
                        {dayjs(m.timestamp).format("h:mma DD/MM/YYYY").replace(todayDate, "today")}
                      </Typography>
                      <Box
                        sx={{
                          py: 1,
                          px: 2,
                          bgcolor: myMessage
                            ? theme => theme.palette.success.light + "3f"
                            : userMessage
                              ? theme => theme.palette.info.light + "3f"
                              : "background.paper",
                          borderRadius: 4,
                          position: "relative",
                        }}
                      >
                        <Icon
                          fontSize="small"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            transform: "translate(-25%, -25%)",
                          }}
                        />
                        <MessageBody {...m} />
                      </Box>
                      {sender && (
                        <Typography variant="caption">
                          Sent by {myMessage ? "me" : userMessage ? "user" : sender}
                        </Typography>
                      )}
                    </Box>
                  );
                })}
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {selectedUser && (
              <ContactForm
                user={selectedUser}
                messageType={selectedUser.accountType}
                onSend={handleNewMessage}
              />
            )}
          </Grid>
        </Grid>
      )}
    </DefaultLayout>
  );
};

export default InboxScreen;

import { Box } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";

export type PulseStage = "stopped" | "in" | "pause-in" | "pause-out" | "out";
type Props = {
  direction: PulseStage;
  inDuration: number;
  outDuration: number;
  pauseDuration: number;
};
const initialStyles = {
  width: "50%",
  height: "50%",
};
const inStyles = {
  opacity: 0.2,
  width: "100%",
  height: "100%",
};
const outStyles = {
  opacity: 1,
  width: "50%",
  height: "50%",
};
const Pulse: FC<Props> = ({ direction, inDuration, outDuration, pauseDuration }) => {
  const [initial, setInitial] = useState(true);
  const [duration, styles] = useMemo(() => {
    switch (direction) {
      case "out":
        return [outDuration, outStyles];
      case "in":
        return [inDuration, inStyles];
      case "pause-in":
        return [pauseDuration, outStyles];
      case "pause-out":
        return [pauseDuration, inStyles];
      case "stopped":
        return [1, initialStyles];
    }
  }, [direction, inDuration, outDuration, pauseDuration]);
  useEffect(() => setInitial(false), []);

  return (
    <Box
      sx={{
        position: "absolute",
        transition: `opacity ${duration}s ease-in-out, width ${duration}s ease-out, height ${duration}s ease-out`,
        bgcolor: "common.white",
        borderRadius: "50%",
        ...(initial ? initialStyles : styles),
      }}
    ></Box>
  );
};

export default Pulse;

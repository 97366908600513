import { FC, useMemo } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { CommentOutlined, Search } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

import IllustrationLayout from "./layouts/IllustrationLayout";
import { formatFriendlyDate } from "~/utils/date";

import { ReactComponent as Brain } from "~/assets/brainIcon.svg";

import mobileSuccessImage from "~/assets/mobile-blue-hoodie.png";
import desktopSuccessImage from "~/assets/desktop-blue-hoodie.png";
import NavLink from "~/components/NavLink";
import { pxToRem } from "~/theme";
import BrandedBox from "~/components/containers/BrandedBox";

const nextSteps = [
  {
    icon: CommentOutlined,
    title: "Connect with a community of other young people",
    description: "A safe and anonymous space to share your story or offer your support.",
    actionLabel: "Join the online community",
    actionUrl: "https://au.reachout.com/online-community",
  },
  {
    icon: Brain,
    title: "Quiz: Check in with yourself",
    description:
      "Take this quiz to reflect on how you’re going, and find out what you can do to feel better.",
    actionLabel: "Take the quiz",
    actionUrl: "https://au.reachout.com/articles/quiz-checking-in-with-yourself",
  },
  {
    icon: Search,
    title: "Learn how to improve your wellbeing",
    description:
      "Browse our information on wellbeing, including self-care tips, coping strategies and communication skills.",
    actionLabel: "Get tips",
    actionUrl: "https://au.reachout.com/mental-wellbeing",
  },
  {
    icon: Search,
    title: "Find personalised support",
    description: "Use NextStep to find out where you can go for support that suits you.",
    actionLabel: "Check out NextStep",
    actionUrl: "https://au.reachout.com/articles/not-sure-whats-wrong-try-nextstep",
  },
];

const BookingSuccess: FC = () => {
  const location = useLocation();

  const [time, dayDisplay] = useMemo(() => {
    const bookingDate = dayjs(location.state.start).format("h:mma");

    return [bookingDate, formatFriendlyDate(location.state.start)];
  }, [location.state.start]);

  return (
    <IllustrationLayout
      title="Thank you for booking a chat"
      imageSrc={[mobileSuccessImage, desktopSuccessImage]}
      introSection={
        <BrandedBox
          themeType="navy"
          sx={{
            mt: -2,
            mb: 3,
            py: 4,
          }}
        >
          <Container maxWidth="md" sx={{ px: [5, 0] }}>
            <Typography fontWeight={700}>
              We’ll be chatting at:
              <br />
              {time} {dayDisplay}
            </Typography>
            <Typography>
              On the morning of your chat, you’ll receive a reminder with a link to enter.
            </Typography>
            <NavLink to={`/booking/${location.state?.id}`} color="inherit">
              Edit or cancel your booking
            </NavLink>
          </Container>
        </BrandedBox>
      }
    >
      <Typography variant="h2">What else can I do in the meantime?</Typography>
      <Stack spacing={2}>
        {nextSteps.map(({ icon: Icon, title, description, actionLabel, actionUrl }) => (
          <Stack
            key={title}
            p={2}
            spacing={2}
            sx={{ bgcolor: "background.paper" }}
            alignItems="flex-start"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "& svg": {
                  width: "1.5rem",
                  height: "1.5rem",
                },
              }}
            >
              <Icon />
              <Typography variant="h4" component="h3" sx={{ ml: 1 }}>
                {title}
              </Typography>
            </Box>

            <Typography>{description}</Typography>
            <Button href={actionUrl} target="_blank" sx={{ fontSize: pxToRem(16) }}>
              {actionLabel}
            </Button>
          </Stack>
        ))}
      </Stack>
    </IllustrationLayout>
  );
};

export default BookingSuccess;

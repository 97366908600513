import { FC, useContext, useState } from "react";
import { Alert, AlertColor, Box, Button, Link, Stack, Typography } from "@mui/material";
import { Auth } from "@aws-amplify/auth";

import { AuthContext } from "~/components/providers/AuthProvider";
import { useForm, FormProvider } from "react-hook-form";
import LoadingButton from "../LoadingButton";
import PasswordField from "../PasswordField";

interface ChangePasswordValues {
  username: string;
  currentPassword: string;
  newPassword: string;
}
const ChangePassword: FC = () => {
  const [changingPassword, setChangingPassword] = useState(false);
  const { authUser } = useContext(AuthContext);
  const [statusMessage, setStatusMessage] = useState<{
    msg: string;
    severity: AlertColor;
  }>({ msg: "", severity: "success" });

  const methods = useForm<ChangePasswordValues>();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: ChangePasswordValues) => {
    try {
      await Auth.changePassword(authUser, data.currentPassword, data.newPassword);
      setStatusMessage({
        msg: "Password reset successfully!",
        severity: "success",
      });
      setChangingPassword(false);
    } catch (ex) {
      console.error(ex);
      setStatusMessage({ msg: "Password reset failed!", severity: "error" });
    }
  };

  if (!changingPassword) {
    return (
      <Box>
        <Typography component="p" sx={{ mt: 3, textAlign: ["center", "right"] }}>
          Need to{" "}
          <Link
            aria-label="Need to change your password?"
            onClick={() => {
              setChangingPassword(true);
            }}
            href="#"
          >
            change your password?
          </Link>
        </Typography>
        {statusMessage.msg && <Alert severity={statusMessage.severity}>{statusMessage.msg}</Alert>}
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <PasswordField
          name="currentPassword"
          label="Current password"
          autoComplete="current-password"
        />

        <PasswordField name="newPassword" label="New password" autoComplete="new-password" />

        {statusMessage.msg && <Alert severity={statusMessage.severity}>{statusMessage.msg}</Alert>}

        <Stack
          direction={["column", "row"]}
          sx={{
            justifyContent: "space-between",
            flexFlow: ["column", "row-reverse"],
          }}
        >
          <LoadingButton color="primary" type="submit" loading={isSubmitting}>
            Update your password
          </LoadingButton>
          <Button color="primary" variant="text" onClick={() => setChangingPassword(false)}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default ChangePassword;

import { type FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppBar, Toolbar, Box, Drawer, Stack } from "@mui/material";

import MenuOptions from "~/components/MenuOptions";
import NavLink from "./NavLink";
import { colours } from "~/theme";
import SlimlineHeader from "./header/SlimLineHeader";

type Props = {
  // used when displaying the Overseas User intercept so the only actions are
  // what's shown in the intercept modal
  hideHeaderActions?: boolean;
};

const Header: FC<Props> = ({ hideHeaderActions = false }) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const urgentHelpUrl = "https://au.reachout.com/urgent-help";
  const handleToggleMenu = useCallback(() => setMenuOpen(current => !current), [setMenuOpen]);

  const handleClose = useCallback(() => setMenuOpen(false), [setMenuOpen]);

  // close menu and scroll to top when navigating
  useEffect(() => {
    handleClose();
    document.getElementById("main-content")?.scrollTo({ top: 0 });
  }, [handleClose, location.pathname]);

  return (
    <>
      <AppBar color="navy" enableColorOnDark elevation={0}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <Stack direction="column">
              <Stack>
                <SlimlineHeader
                  menuOpen={menuOpen}
                  hideHeaderActions={hideHeaderActions}
                  handleToogle={handleToggleMenu}
                  urgentHelpUrl={urgentHelpUrl}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  px: 3,
                  borderBottom: `0.5px solid ${colours.white.main}`,
                }}
                height="48px"
              >
                <NavLink
                  id="peerchat-home"
                  to="/"
                  color="inherit"
                  variant="body1"
                  fontWeight="400 !important"
                  lineHeight="1.75"
                  sx={{ textDecoration: "none" }}
                >
                  PeerChat
                </NavLink>
                {hideHeaderActions ? (
                  <Box />
                ) : (
                  <Box id="header-actions" sx={{ display: "flex", alignItems: "center" }}></Box>
                )}
              </Stack>
            </Stack>
          </Box>
          <Drawer anchor="right" open={menuOpen} onClose={handleClose}>
            <MenuOptions onClose={handleClose} />
          </Drawer>
        </Toolbar>
      </AppBar>
      {/* <Offset /> */}
      <Box minHeight="112px" />
    </>
  );
};

export default Header;

import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FC } from "react";
import BrandedBox from "../containers/BrandedBox";
import { colours } from "~/theme";

type PopUpProps = {
  open: boolean;
  heading: string;
  bodyText: string;
  buttonText: string;
  onExit: React.MouseEventHandler;
  onButtonClick: React.MouseEventHandler;
};

const PopUp: FC<PopUpProps> = ({ open, heading, bodyText, buttonText, onExit, onButtonClick }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: "24px",
          border: "none",
          backgroundColor: colours.navy.main,
        },
      }}
    >
      <BrandedBox themeType="navy">
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            px: 5,
            pt: 3,
            fontSize: "h2.fontSize",
          }}
        >
          <Box flex={1}>{heading}</Box>
          <IconButton
            aria-label="Close dialog"
            onClick={onExit}
            sx={{
              color: colours.white.main,
              position: "absolute",
              top: "22px",
              right: "21px",
              backgroundColor: "transparent",
              border: "0",
              cursor: "pointer",
              fontSize: "10px",
              height: "14px",
              lineHeight: "1",
              width: "14px",
            }}
          >
            <CloseRounded />
          </IconButton>
        </DialogTitle>

        <Box flex={1} sx={{ px: 5, pb: 1 }}>
          <Typography>{bodyText}</Typography>
        </Box>

        <DialogActions sx={{ px: 5, pb: 3 }}>
          <Button color="plum" onClick={onButtonClick} fullWidth>
            {buttonText}
          </Button>
        </DialogActions>
      </BrandedBox>
    </Dialog>
  );
};

export default PopUp;

import { FC, useState } from "react";

import { Auth } from "@aws-amplify/auth";
import { Alert, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PasswordField from "~/components/PasswordField";
import FormField from "../FormField";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../LoadingButton";
import { normaliseUsername } from "./utils";
import { withPrettyLabels } from "~/utils/validation";

interface SetPasswordValues {
  emailAddressOrMobileNumber: string;
  verificationCode: string;
  newPassword: string;
}

const setSchema = withPrettyLabels(
  yup.object({
    emailAddressOrMobileNumber: yup
      .string()
      .required("An email address or mobile number is required"),
    verificationCode: yup.string().required(),
    newPassword: yup.string().required(),
  })
);

type Props = {
  username: string;
};
const ForgotPasswordNewForm: FC<Props> = ({ username }) => {
  const navigate = useNavigate();
  const [serverError, setServerError] = useState("");

  const setMethods = useForm({
    resolver: yupResolver(setSchema),
    defaultValues: {
      emailAddressOrMobileNumber: username,
      verificationCode: "",
      newPassword: "",
    },
  });

  const onSubmit = async (data: SetPasswordValues) => {
    try {
      setServerError("");
      await Auth.forgotPasswordSubmit(
        normaliseUsername(data.emailAddressOrMobileNumber),
        data.verificationCode,
        data.newPassword
      );
      navigate("/");
    } catch (ex) {
      setServerError((ex as Error).message || "Failed to set new password");
      console.error("Failed to set new password", ex);
    }
  };

  return (
    <FormProvider {...setMethods}>
      <form onSubmit={setMethods.handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2}>
          <FormField
            name="emailAddressOrMobileNumber"
            label="Email or Phone number"
            required
            inputProps={{ readOnly: true }}
          />
          <FormField name="verificationCode" label="Verification code" required />
          <PasswordField
            name="newPassword"
            label="New password"
            autoComplete="new-password"
            required
          />

          {serverError && <Alert severity="error">{serverError}</Alert>}
          <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
            <LoadingButton
              color="primary"
              type="submit"
              loading={setMethods.formState.isSubmitting}
            >
              Set new password
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordNewForm;

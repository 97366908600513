import { FC, useContext } from "react";
import SBProvider from "@sendbird/uikit-react/SendbirdProvider";
import { useTheme } from "@mui/material";
import { AuthContext } from "~/components/providers/AuthProvider";
import LoginForm from "../auth/LoginForm";
import DefaultLayout from "~/screens/layouts/DefaultLayout";

const appId = process.env.REACT_APP_SENDBIRD_APPID!;

type SendbirdProviderProps = {
  children: React.ReactNode;
};

const SendbirdProvider: FC<SendbirdProviderProps> = ({ children }) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);

  if (!user?.userId) {
    return (
      <DefaultLayout title="Log in to chat">
        <LoginForm noRedirect />
      </DefaultLayout>
    );
  }

  return (
    <SBProvider
      appId={appId}
      theme={theme.palette.mode}
      // colorSet={myColorSet}
      userId={user.userId}
      accessToken={user.accessToken}
      nickname={user.nickname}
      stringSet={{
        CHANNEL_FROZEN: "This chat has ended",
        MESSAGE_INPUT__PLACE_HOLDER__DISABLED:
          "This chat has ended, you can't send messages anymore",
      }}
    >
      {children}
    </SBProvider>
  );
};

export default SendbirdProvider;

import { FC } from "react";
import { useParams } from "react-router-dom";
import ChatSession from "~/components/ChatSession";
import SendbirdProvider from "~/components/providers/SendbirdProvider";

import "@sendbird/uikit-react/dist/index.css";

const SessionScreen: FC = () => {
  const { sessionId } = useParams();

  if (!sessionId) return null;

  return (
    <SendbirdProvider>
      <ChatSession sessionId={sessionId.trim()} />
      {/* // Added trim to remove whitespace caused by an appended %0D in some urls */}
    </SendbirdProvider>
  );
};

export default SessionScreen;

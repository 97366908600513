import { IconButton as IC, IconButtonProps } from "@mui/material";
import { colours } from "~/theme";

const IconButton = ({
  label,
  onClick,
  sx,
  children,
  disabled,
}: {
  label: string;
  onClick: () => void;
  sx?: IconButtonProps["sx"];
  children: React.ReactNode;
  disabled?: boolean;
}) => (
  <>
    <IC
      aria-label={label}
      onClick={onClick}
      disableRipple
      disabled={disabled}
      sx={{
        color: colours.text.dark,
        width: 40,
        backgroundColor: colours.peri.main,
        "&:hover": {
          backgroundColor: colours.peri.light,
        },
        "&.Mui-disabled": {
          backgroundColor: colours.grey[80],
          color: colours.white.main,
        },
        ...sx,
      }}
    >
      {children}
    </IC>
  </>
);
export default IconButton;

import { FC } from "react";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { ReactComponent as EndChatIcon } from "~/assets/sendbirdEndChatIcon.svg";
import { colours } from "~/theme";
import BrandedBox from "../containers/BrandedBox";

type Props = {
  open: boolean;
  handleSessionEnd: React.MouseEventHandler;
  closeModal: React.MouseEventHandler;
  isStaffMember: boolean;
};

const LeaveChatModal: FC<Props> = ({ open, handleSessionEnd, closeModal, isStaffMember }) => {
  return (
    <Dialog open={open} fullScreen PaperProps={{ sx: { backgroundColor: colours.navy.tint } }}>
      <Stack
        spacing={3}
        alignItems="center"
        px={3}
        flex={1}
        justifyContent={{ xs: "flex-end", sm: "center" }}
      >
        <EndChatIcon width="60px" />
        <Typography variant="h2" color={colours.white.main} textAlign="center">
          {isStaffMember
            ? "Are you sure you're ready to launch survey? Ensure that the chat is locked before proceeding."
            : "Are you ready to leave the chat?"}
        </Typography>
        <BrandedBox themeType="transparent">
          <Stack spacing={2} my={10} sx={{ width: 200, "&&": { my: 10 } }}>
            <Button onClick={handleSessionEnd}>{isStaffMember ? "Yes" : "Leave chat"}</Button>
            <Button color="primary" variant="outlined" onClick={closeModal}>
              {isStaffMember ? "No" : "Cancel"}
            </Button>
          </Stack>
        </BrandedBox>
      </Stack>
    </Dialog>
  );
};

export default LeaveChatModal;

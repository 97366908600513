import { type FC, useContext, useMemo, useState } from "react";
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { PeerWorkerContext } from "../providers/PeerWorkerProfileProvider";
import { colours } from "~/theme";
import CheckInSliderIteration from "../chat/CheckInSliderIteration";
import { type PreCheckInNames, preCheckInValues, preSliderValues } from "~/content/checkInData";
import ROIconButton from "~/components/IconButton";
import DoneIcon from "@mui/icons-material/Done";

type Props = {
  onCheckin: (value?: string) => void;
};

const CheckIn: FC<Props> = ({ onCheckin }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { displayName } = useContext(PeerWorkerContext);

  const sliderValuesList = Object.keys(preCheckInValues) as PreCheckInNames[];

  const [checkIn, setCheckIn] = useState<{
    numberValue: number | undefined;
    textValue: PreCheckInNames;
    touched: boolean;
  }>({
    numberValue: undefined,
    textValue: "Skipped check-in",
    touched: false,
  });

  const weatherImgUrl = useMemo(() => {
    if (checkIn.textValue === "Skipped check-in")
      return isMobile
        ? preCheckInValues["Skipped check-in"].mobileIconSrc
        : preCheckInValues["Skipped check-in"].iconSrc;

    return isMobile
      ? preCheckInValues[checkIn.textValue].mobileIconSrc
      : preCheckInValues[checkIn.textValue].iconSrc;
  }, [checkIn.textValue, isMobile]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setCheckIn({
      numberValue: newValue as number,
      textValue: sliderValuesList[newValue as number],
      touched: true,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: colours.navy.main,
        color: colours.white.main,
      }}
      height="100%"
    >
      <Stack
        spacing={{ xs: 0, md: 2 }}
        alignItems="center"
        px={{ xs: 1, md: 3 }}
        pt={{ xs: 1, md: 3 }}
      >
        <Typography variant="h4" align="center">
          How are you feeling about what's going on?
        </Typography>
        <Typography align="center">
          Let {displayName || "your peer worker"} know how you're feeling today to help them prepare
          for your chat.
        </Typography>
        <Box sx={{ width: "100%", backgroundColor: "navy.main" }}>
          <Stack
            sx={{ px: { xs: 2, md: 10 }, py: 5 }}
            spacing={{ xs: "unset", md: 5 }}
            direction={{ xs: "row", md: "column" }}
            justifyContent={{ xs: "space-evenly", md: "column" }}
            alignItems="center"
          >
            <Box
              width={{ xs: "110px", md: "unset" }}
              maxWidth={{ xs: "unset", md: "450px" }}
              component="img"
              height={{ xs: "unset", md: "200px" }}
              position="relative"
              top="-10px"
              src={weatherImgUrl}
              alt={""}
            />
            <Box width={{ xs: "160px", md: "100%" }} maxWidth="md">
              <CheckInSliderIteration
                sliderValues={preSliderValues}
                vertical={isMobile}
                checkInValue={checkIn.numberValue}
                touched={checkIn.touched}
                handleSliderChange={handleSliderChange}
                checkinStringValue={sliderValuesList}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Box
        position={{ xs: "fixed", md: "unset" }}
        bottom={{ xs: "16px", md: "unset" }}
        right={{ xs: "16px", md: "unset" }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} px={3}>
          <Button
            variant="text"
            onClick={() => onCheckin("Skipped check-in")}
            sx={{
              paddingRight: 1,
              color: "white",
              fontWeight: "unset",
              textDecoration: "none",
            }}
          >
            Skip
          </Button>
          <ROIconButton
            label="next"
            onClick={() => onCheckin(checkIn.textValue)}
            disabled={!checkIn.touched}
          >
            <DoneIcon />
          </ROIconButton>
        </Stack>
      </Box>
    </Box>
  );
};

export default CheckIn;

import { Alert, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingButton from "~/components/LoadingButton";
import LoadingIndicator from "~/components/LoadingIndicator";
import { cancelBooking, getMyBookings } from "~/services/booking";
import { formatBookingDate } from "~/utils/date";
import DefaultLayout from "./layouts/DefaultLayout";

const ManageBookingScreen = () => {
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [booking, setBooking] = useState<Booking>();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    getMyBookings()
      .then(res => {
        setBooking(res.find(r => r.id === bookingId));
      })
      .finally(() => setLoading(false));
  }, [bookingId]);

  const handleCancel = async () => {
    setSubmitting(true);
    try {
      await cancelBooking(bookingId!);
      navigate("/", {
        state: { successMessage: "Chat cancelled successfully" },
      });
    } catch (ex) {
      console.error(ex);
      setErrorMessage("Unable to cancel booking. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <DefaultLayout title="Your chat" noHeaderColour sx={{ pt: 2 }}>
      {loading ? (
        <LoadingIndicator />
      ) : booking ? (
        <>
          <Typography variant="h2" component="p" mb={8}>
            Your next chat is booked for {formatBookingDate(booking.start)}
          </Typography>
          <Typography>
            If something's come up and you need to cancel your booking, that's all good.
          </Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <LoadingButton
            id="cancel-booking"
            loading={submitting}
            onClick={handleCancel}
            sx={{ alignSelf: "flex-start" }}
          >
            Cancel this chat
          </LoadingButton>
        </>
      ) : (
        <Typography>Booking not found</Typography>
      )}
    </DefaultLayout>
  );
};

export default ManageBookingScreen;

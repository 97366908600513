import { FC, useContext } from "react";
import { Button } from "@mui/material";
import { Auth } from "@aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";

import { AuthContext } from "~/components/providers/AuthProvider";
import NavButton from "../NavButton";

const AuthButton: FC = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoggedIn) {
    return (
      <Button
        onClick={async () => {
          await Auth.signOut();
          navigate("/");
        }}
      >
        Log out
      </Button>
    );
  }

  return (
    <>
      <NavButton color="primary" to="/login" state={{ returnTo: location.pathname }}>
        Log in
      </NavButton>
    </>
  );
};

export default AuthButton;

import { FC, useContext, useMemo, useState } from "react";
import DefaultLayout, { DefaultLayoutProps } from "~/screens/layouts/DefaultLayout";
import IllustrationLayout, { IllustrationLayoutProps } from "~/screens/layouts/IllustrationLayout";

import CheckIn from "./CheckIn";
import ExerciseSelection from "./ExerciseSelection";
import BreathingExercise from "./BreathingExercise";
import NatureSounds from "./NatureSounds";
import PeerWorkInfo from "./PeerWorkInfo";
import ProfileCard from "../ProfileCard";
import { PeerWorkerContext } from "../providers/PeerWorkerProfileProvider";
import { Breakpoint, Typography } from "@mui/material";
import Ready from "./Ready";
import { colours } from "~/theme";

type TextAlignment = "center" | "right" | "left" | "inherit" | "justify" | undefined;

type LayoutProps =
  | ({
      imageSrc: IllustrationLayoutProps["imageSrc"];
    } & IllustrationLayoutProps)
  | ({ imageSrc: undefined } & DefaultLayoutProps);
const Layout: FC<LayoutProps> = ({ children, ...props }) => {
  if (props.imageSrc) {
    return <IllustrationLayout {...props}>{children}</IllustrationLayout>;
  }
  return (
    <DefaultLayout noHeaderColour {...props}>
      {children}
    </DefaultLayout>
  );
};

type Props = {
  onJoin: () => void;
  onCheckin: (value?: string) => void;
  hasStarted: boolean;
};
export type WaitingStage = "info" | "checkin" | "waiting" | "breathe" | "sounds";

const WaitingRoomHelpSeeker: FC<Props> = ({ hasStarted, onJoin, onCheckin }) => {
  const [waitingStage, setWaitingStage] = useState<WaitingStage>("info");
  const peerWorkerProfile = useContext(PeerWorkerContext);
  const [hasCheckedIn, setCheckedIn] = useState(false);

  const { children, ...res } = useMemo(() => {
    switch (waitingStage) {
      case "info":
        return {
          title: "You will be chatting with...",
          titleAlign: "center" as TextAlignment,
          imageSrc: undefined,
          children: (
            <>
              <ProfileCard {...peerWorkerProfile} />
              <PeerWorkInfo onClose={() => setWaitingStage("checkin")} />
            </>
          ),
          containerWidth: "sm" as Breakpoint,
        };
      case "breathe":
        return {
          stackSx: { height: "100%" },
          children: (
            <BreathingExercise
              hasStarted={hasStarted}
              onJoin={onJoin}
              setWaitingStage={setWaitingStage}
            />
          ),
        };
      case "sounds":
        return {
          children: (
            <NatureSounds
              hasStarted={hasStarted}
              onJoin={onJoin}
              setWaitingStage={setWaitingStage}
            />
          ),
        };
      case "waiting":
        return {
          title: `${peerWorkerProfile.displayName}’s ${
            hasStarted ? "ready" : "preparing"
          } for your chat`,
          titleAlign: "center" as TextAlignment,
          stackSx: { pb: 2 },
          children: hasStarted ? (
            <Ready onJoin={onJoin} />
          ) : (
            <>
              <Typography align="center" sx={{ mt: -2 }}>
                We’ll let you know as soon as they're ready!
              </Typography>
              <ProfileCard {...peerWorkerProfile} />
              <ExerciseSelection
                hasStarted={hasStarted}
                onJoin={onJoin}
                onSelect={stage => setWaitingStage(stage)}
                hasCheckedIn={hasCheckedIn}
              />
            </>
          ),
          containerWidth: "sm" as Breakpoint,
        };
      case "checkin":
      default:
        return {
          titleAlign: "center" as TextAlignment,
          sx: {
            px: "0px !important",
            backgroundColor: colours.navy.main,
          },
          middleSection: (
            <CheckIn
              onCheckin={(value?: string) => {
                setWaitingStage("waiting");
                onCheckin(value);
                setCheckedIn(value !== undefined && value !== "Skipped check-in");
              }}
            />
          ),
        };
    }
  }, [waitingStage, hasStarted, onJoin, onCheckin, hasCheckedIn, peerWorkerProfile]);

  return (
    <Layout {...res} sx={{ height: "100%", pb: 0, ...res.sx }}>
      {children}
    </Layout>
  );
};

export default WaitingRoomHelpSeeker;

import { FC, ReactNode } from "react";

import {
  Container,
  Stack,
  Box,
  BoxProps,
  Typography,
  useTheme,
  styled,
  Theme,
  ContainerProps,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { colours } from "~/theme";

export type IllustrationLayoutProps = {
  title?: string;
  imageSrc: string | [string, string];
  introSection?: ReactNode;
  sx?: ContainerProps["sx"];
  headerSx?: BoxProps["sx"];
  children: ReactNode;
};

const Image = styled("img")({
  width: "100%",
  height: 250, // initial sizing to reduce CLS. removed on image load
  maxHeight: 360,
  objectFit: "contain",
  verticalAlign: "bottom",
});

const getFilename = (imageSrc: IllustrationLayoutProps["imageSrc"]) =>
  typeof imageSrc === "string" ? imageSrc : imageSrc[0];

const getMetadataFromFilename = (filename: string) => {
  let colour = filename.replace(/^.*(mobile|desktop)-/, "").replace(/-.+$/, "") as
    | "pink"
    | "blue"
    | "yellow"
    | undefined;
  if (!colour?.match(/(pink|yellow|blue)/)) colour = undefined;
  const headingBg =
    colour && colours.illustrations[colour]
      ? colours.illustrations[colour]
      : (theme: Theme) => theme.palette.headingBg.main;
  const headingColour = colour
    ? colours.text.dark
    : (theme: Theme) => theme.palette.headingBg.contrastText;

  const shouldOverlap = filename.includes("overlap");
  return {
    colour,
    headingBg,
    headingColour,
    shouldOverlap,
  };
};

const asSrcSetProps = (sources: [string, string], breakpoint = 600) => ({
  srcSet: `${sources[0]} 200w, ${sources[1]} 1200w`,
  sizes: `(max-width: ${breakpoint}px) 100px, 1200px`,
});

const IllustrationLayout: FC<IllustrationLayoutProps> = ({
  title,
  imageSrc,
  introSection,
  children,
  sx,
  headerSx,
}) => {
  const theme = useTheme();
  const mobileFilename = getFilename(imageSrc);
  const { headingBg, headingColour, shouldOverlap } = getMetadataFromFilename(mobileFilename);
  const imageProps =
    typeof imageSrc === "string"
      ? { src: imageSrc }
      : asSrcSetProps(imageSrc, theme.breakpoints.values.sm);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        sx={{
          pt: 4,
          mb: 2,
          bgcolor: headingBg,
          color: headingColour,
          ...(headerSx || {}),
        }}
      >
        <Container maxWidth="md" sx={{ flex: 1, "&&": { px: 0 } }}>
          {title && (
            <Typography variant="h1" sx={{ mb: 2, px: [5, 0] }}>
              {title}
            </Typography>
          )}
          <Image
            sx={{
              mb: shouldOverlap ? -10 : undefined,
            }}
            {...imageProps}
            onLoad={(e: any) => ((e.target as HTMLImageElement).style.height = "auto")}
            alt="" // TODO: is the image purely presentational?
          />
        </Container>
      </Box>
      {introSection}
      <Container
        maxWidth="md"
        sx={{
          flex: 1,
          px: [5, 0],
          pb: 4,
          pt: shouldOverlap ? 5 : 0,
          ...(sx || {}),
        }}
      >
        <Stack spacing={2}>{children}</Stack>
      </Container>
    </>
  );
};

export default IllustrationLayout;

import { Box, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import axios from "axios";
import { Auth } from "@aws-amplify/auth";

import DefaultLayout from "./layouts/DefaultLayout";
import CreateUserForm from "~/components/admin/CreateUserForm";
// import CreateSessionForm from "~/components/admin/CreateSessionForm";
import LoadingIndicator from "~/components/LoadingIndicator";
import ReassignChatForm from "~/components/admin/ReassignChatForm";

const AdminScreen: FC = () => {
  const [users, setUsers] = useState<ContactableUser[]>([]);
  useEffect(() => {
    (async () => {
      axios
        .get(`${process.env.REACT_APP_API_BASE}/users`, {
          headers: {
            Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
          },
          params: {
            filter: "peerworker",
          },
        })
        .then(res => setUsers(res.data));
    })();
  }, []);

  return (
    <DefaultLayout containerWidth="lg" sx={{ py: [2, 5] }}>
      <Typography variant="h1">Admin functions</Typography>

      <Stack
        spacing={2}
        direction={["column", "row"]}
        sx={{
          "&& > * + *": {
            mt: [2, 0],
            ml: [0, 2],
          },
        }}
      >
        <CreateUserForm sx={{ flex: 1 }} />

        {/* create a session is not in active use at this moment */}
        {/* but I'm not feeling ready to delete yet */}
        {/* {!users?.length ? (
          <Box sx={{ flex: 1 }}>
            <LoadingIndicator />
          </Box>
        ) : (
          <CreateSessionForm users={users} sx={{ flex: 1 }} />
        )} */}

        {!users?.length ? (
          <Box sx={{ flex: 1 }}>
            <LoadingIndicator />
          </Box>
        ) : (
          <ReassignChatForm users={users} sx={{ flex: 1 }} />
        )}
      </Stack>
    </DefaultLayout>
  );
};

export default AdminScreen;

import { FC, useCallback, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { Button, Stack, Typography, Alert, MenuItem, Link } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { phrase } from "@reachout/friendly-phrase";

import { normaliseUsername, emailOrPhoneParams } from "~/components/auth/utils";
import FormField from "~/components/FormField";
import PasswordField from "~/components/PasswordField";
import LoadingButton from "~/components/LoadingButton";

import { withPrettyLabels, displayNameSchema, phoneSchema } from "~/utils/validation";

import { buildRecaptcha } from "./recaptcha";
import FormCheckbox from "../FormCheckbox";
import { getCurrentTimezone } from "~/utils/date";
import { Helmet } from "react-helmet-async";

export const randomUsername = (): string => {
  return phrase("-", "", true).replace(/[^a-z]+/gi, "_");
};

const ageRange = Array.from(Array(26 - 16), (_x, i) => 16 + i);

const signupSchema = withPrettyLabels(
  yup.object({
    displayName: displayNameSchema,
    age: yup.string().required(),
    mobileNumber: phoneSchema,
    password: yup.string().required().min(8),
    acceptTerms: yup
      .boolean()
      .is([true], "You must accept the terms and privacy policy to continue"),
  })
);
interface SignupFormValues {
  mobileNumber: string;
  displayName: string;
  age: string;
  password: string;
  acceptTerms?: boolean;
}

type Props = {
  onLoginClick: (e: React.MouseEvent) => void;
  onSignup: () => void;
};
const SignupForm: FC<Props> = ({ onLoginClick, onSignup }) => {
  const navigate = useNavigate();

  const [formError, setFormError] = useState("");
  const methods = useForm<SignupFormValues>({
    resolver: yupResolver(signupSchema),
    defaultValues: { displayName: "", acceptTerms: false },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
  } = methods;
  const displayName = watch("displayName");
  const onSubmit = async (data: SignupFormValues) => {
    setFormError("");

    try {
      const recaptchaValue = await buildRecaptcha();

      // if passing phone, normalise it to E.164 format
      const username = normaliseUsername(data.mobileNumber);

      // Intl may return undefined in some browsers. Assume sydney as fallback - user can change it
      const currentTimezone = getCurrentTimezone();

      const userData = {
        username: uuidv4(),
        password: data.password,
        attributes: {
          preferred_username: data.displayName.trim(),
          zoneinfo: currentTimezone,
          "custom:ageAtSignup": data.age,
          ...emailOrPhoneParams(data.mobileNumber),
        },
      };

      await Auth.signUp({
        ...userData,
        validationData: {
          recaptchaValue: recaptchaValue,
        },
      });

      await Auth.signIn(userData);

      if (window.heap) {
        window.heap.addUserProperties({
          age: String(data.age),
          accountType: username.startsWith("+") ? "mobile" : "email",
        });
        window.heap.track("signup-complete");
        onSignup();
      }

      navigate("/booking/availability");
    } catch (ex) {
      console.error(ex);
      setFormError("Signup failed"); // TODO: better error messages
    }
  };

  const handleGenerateName = useCallback(() => {
    setValue("displayName", randomUsername(), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [setValue]);

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>Make an account and start chatting</title>
        <meta
          name="description"
          content="Sign up to PeerChat and book a free and confidential session with a peer worker. Making an account takes only a moment!"
        />
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={2}>
          <Typography sx={{ mb: 4 }}>
            Already have an account?{" "}
            <Link color="primary" href="#" onClick={onLoginClick}>
              Log in
            </Link>
          </Typography>
          <FormField
            name="displayName"
            label="Display name"
            required
            // Not sure why this needs to be manually controlled
            // but when generating the name it doesn't correctly update
            InputLabelProps={{ shrink: Boolean(displayName) }}
            InputProps={{
              endAdornment: (
                <Button
                  variant="outlined"
                  color="inherit"
                  title="Generate a display name"
                  onClick={handleGenerateName}
                  sx={{ fontSize: "0.875rem", whiteSpace: "nowrap", px: 3 }}
                >
                  Go random
                </Button>
              ),
            }}
          />

          <FormField name="age" label="Age" required select>
            {ageRange.map(i => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </FormField>

          <FormField name="mobileNumber" label="Mobile number" required />
          <Typography>
            We will send a reminder of your chat time and a link to the chat to this mobile phone
            number.
          </Typography>

          <PasswordField name="password" label="Password" required autoComplete="new-password" />

          <Typography>
            All your information is completely secure and anonymous. We only collect it to help you
            manage your booking and in case of an emergency.
          </Typography>

          <FormCheckbox
            name="acceptTerms"
            label={
              <>
                I agree to ReachOut&apos;s{" "}
                <Link
                  href="https://au.reachout.com/our-policies/terms-and-conditions"
                  target="_blank"
                >
                  Terms and conditions
                </Link>{" "}
                and{" "}
                <Link href="https://au.reachout.com/our-policies/privacy-policy" target="_blank">
                  Privacy policy
                </Link>
              </>
            }
          />

          {formError && <Alert severity="error">{formError}</Alert>}
          <Stack direction="row">
            <LoadingButton type="submit" loading={isSubmitting}>
              Sign up
            </LoadingButton>
          </Stack>
          <Typography variant="caption" mt={2} fontStyle="italic" textAlign="center">
            This site is protected by reCAPTCHA and the Google{" "}
            <Link href="https://policies.google.com/privacy" target="_blank">
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link href="https://policies.google.com/terms" target="_blank">
              Terms of Service
            </Link>{" "}
            apply.
          </Typography>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default SignupForm;

import { FC } from "react";
import { Container } from "@mui/material";

import ForgotPasswordForm from "~/components/auth/ForgotPasswordForm";

const ForgotPasswordScreen: FC = () => {
  return (
    <Container maxWidth="md" sx={{ p: 2 }}>
      <ForgotPasswordForm />
    </Container>
  );
};

export default ForgotPasswordScreen;

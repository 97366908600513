import { Box, Link, BoxProps } from "@mui/material";
import logo from "~/assets/reachout_logo.svg";
import monogramLogo from "~/assets/reachout_monogram_logo.svg";

type LogoProps = {
  sx?: BoxProps["sx"];
};
const Logo = ({ sx = [] }: LogoProps) => {
  return (
    <Link
      href="https://au.reachout.com"
      sx={{
        display: "flex",
      }}
    >
      <Box
        component="img"
        src={monogramLogo}
        sx={[
          {
            display: { md: "none" },
            width: 62,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        alt="ReachOut home"
      />
      <Box
        component="img"
        src={logo}
        sx={[
          {
            display: { xs: "none", md: "block" },
            width: 150,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        alt="ReachOut home"
      />
    </Link>
  );
};

export default Logo;

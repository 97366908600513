import { Button, Stack } from "@mui/material";
import { FC } from "react";
import { colours } from "~/theme";

type Props = {
  easedFromDifficulty: boolean | undefined;
  handleEasedFromDifficulty: (value: boolean) => void;
};

const ButtonStep: FC<Props> = ({ easedFromDifficulty, handleEasedFromDifficulty }) => {
  return (
    <Stack spacing={1} direction="column" justifyContent="center" maxWidth="sm" width="100%">
      <Button
        onClick={() => handleEasedFromDifficulty(true)}
        sx={{
          "&.MuiButton-root": {
            backgroundColor: easedFromDifficulty ? colours.white.main : colours.grey[80],
            color: easedFromDifficulty ? colours.navy.main : colours.white.main,
            "&:hover": {
              backgroundColor: easedFromDifficulty ? colours.white.dark : colours.grey.light,
            },
          },
        }}
      >
        Yes
      </Button>
      <Button
        onClick={() => handleEasedFromDifficulty(false)}
        sx={{
          "&.MuiButton-root": {
            backgroundColor: easedFromDifficulty === false ? colours.white.main : colours.grey[80],
            color: easedFromDifficulty === false ? colours.navy.main : colours.white.main,
          },
          "&:hover": {
            backgroundColor:
              easedFromDifficulty === false ? colours.white.dark : colours.grey.light,
          },
        }}
      >
        No
      </Button>
    </Stack>
  );
};

export default ButtonStep;

import { FC } from "react";
import { Button, ButtonProps } from "@mui/material";
import LoadingIndicator from "~/components/LoadingIndicator";

type LoadingProps = {
  loading?: boolean;
};

// Loading button is disabled when loading, and when it is explicitly set to disabled
const LoadingButton: FC<ButtonProps & LoadingProps> = ({ loading = false, children, ...props }) => {
  return (
    <Button color="primary" {...props} disabled={loading || props.disabled}>
      {loading && <LoadingIndicator size="1em" sx={{ mr: 1 }} />}
      {children}
    </Button>
  );
};

export default LoadingButton;

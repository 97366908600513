import { type FC, useMemo, useState, createContext } from "react";
import { ThemeProvider } from "@mui/material";

import { darkTheme, lightTheme } from "~/theme";

const preferenceKey = "ro-ps-dark-mode";
const savedDarkModePref = localStorage.getItem(preferenceKey) === "true";

export const ThemePreferenceContext = createContext({
  useDarkTheme: savedDarkModePref,
  setUseDarkTheme: (use: boolean) => {},
});

type DarkModeManagerProps = {
  children: React.ReactNode;
};

const DarkModeManager: FC<DarkModeManagerProps> = ({ children }) => {
  const [useDarkTheme, setUseDarkThemeState] = useState(savedDarkModePref);
  const setUseDarkTheme = (use: boolean) => {
    setUseDarkThemeState(use);
    localStorage.setItem(preferenceKey, String(use));
  };

  const theme = useMemo(() => (useDarkTheme ? darkTheme : lightTheme), [useDarkTheme]);

  return (
    <ThemePreferenceContext.Provider value={{ useDarkTheme, setUseDarkTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemePreferenceContext.Provider>
  );
};

export default DarkModeManager;
